document.addEventListener('DOMContentLoaded', function() {
    // Function to get all query parameters as an object
    function getAllQueryParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const params = {};
        for (const [key, value] of urlParams.entries()) {
            params[key] = value;
        }
        return params;
    }

    // Function to get a cookie value by name
    function getCookie(cname) {
        const name = cname + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // Get all query parameters
    const queryParams = getAllQueryParams();

    // Check if the specific lp_request_id exists and set its value to the corresponding input if needed
    if (queryParams.lp_request_id) {
        const lpInput = document.querySelector('input[data-variable="lp_request_id"]');
        if (lpInput) {
            lpInput.value = queryParams.lp_request_id;
        }
    }

    // Loop through all query parameters
    Object.keys(queryParams).forEach(function(param) {
        // Handle dynamic sub_affiliate_* keys
        if (param.startsWith('sub_affiliate_')) {
            const inputField = document.querySelector(`input[data-variable="${param}"]`);
            if (inputField && queryParams[param]) { // Ensure that the value is not empty
                inputField.value = queryParams[param];
            }
        }
    });

    // Get the Mixpanel ID from the cookie and set it in the micro_user_id input field
    const mixpanelId = getCookie('mixp_id');
    console.log(mixpanelId);
    console.log("HELLO WORLD");
    if (mixpanelId) {
        const microUserIdInput = document.querySelector('input[data-variable="micro_user_id"]');
        if (microUserIdInput) {
            microUserIdInput.value = mixpanelId;
        }
    }
});
